<template>
   <v-container>
      <v-toolbar v-if="$vuetify.breakpoint.mobile" flat floating>
         <v-app-bar-nav-icon @click.stop="$store.commit('toggleDrawer')"></v-app-bar-nav-icon>
         <v-toolbar-title>Dashboard</v-toolbar-title>
      </v-toolbar>
      <v-row>
         <v-col cols="12" sm="6" lg="4">
            <v-card>
               <v-card-text>
                  <h4 class="title font-weight-light">Daily Order</h4>
                  <p class="category d-inline-flex font-weight-light">
                     <v-icon color="green" small >mdi-arrow-up</v-icon>
                     <span class="green--text">55%</span>&nbsp;
                     increase in today's sales
                  </p>
               </v-card-text>
               <v-divider class="mx-3" />
               <v-card-actions>
                  <v-icon class="mr-2" small >mdi-clock-outline</v-icon>
                  <span class="caption grey--text font-weight-light">updated 4 minutes ago</span>
               </v-card-actions>
            </v-card>
         </v-col>
         <v-col cols="12" sm="6" lg="4">
            <v-card>
               <v-card-text>
                  <h4 class="title font-weight-light">Processing Order</h4>
                  <p class="category d-inline-flex font-weight-light">
                     <v-icon color="green" small >mdi-arrow-up</v-icon>
                     <span class="green--text">55%</span>&nbsp;
                     increase in today's sales
                  </p>
               </v-card-text>
               <v-divider class="mx-3" />
               <v-card-actions>
                  <v-icon class="mr-2" small >mdi-clock-outline</v-icon>
                  <span class="caption grey--text font-weight-light">updated 4 minutes ago</span>
               </v-card-actions>
            </v-card>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text class="d-flex mr-3">
                  <b>Live Sessions</b>
                  <v-spacer />
                  <v-btn icon color="indigo" @click="buzz">
                     <v-icon>mdi-refresh</v-icon>
                  </v-btn>
               </v-card-text>
               <v-card-text>
                  <v-simple-table>
                     <template v-slot:default>
                        <thead>
                           <tr>
                              <th class="text-left">Name</th>
                              <th class="text-left">SID</th>
                              <th class="text-left">SOID</th>
                              <th class="text-left">Origin</th>
                              <th class="text-left">Agent</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="(usr, i) in ol_users" :key="i">
                              <td>{{ usr.nama }} &lt;{{usr.user}}&gt;</td>
                              <td>{{ usr.sid }}</td>
                              <td>{{ usr.soid }}</td>
                              <td>{{ usr.origin }}</td>
                              <td>{{ usr.agent }}</td>
                           </tr>
                        </tbody>
                     </template>
                  </v-simple-table>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
      <v-bottom-sheet :value="!!view.open" @click:outside="view={}">
         <v-sheet min-height="200px">
            <v-subheader class="justify-center pa-3">
               <v-btn small class="mx-2" color="blue lighten-3" @click="hapus">status</v-btn>
               <v-btn outlined color="red" @click="view={}" >close</v-btn>
            </v-subheader>
            <v-divider class="mx-4" />

         </v-sheet>
      </v-bottom-sheet>
   </v-container>
</template>

<script>
export default {
   name: 'home',
   data: () => ({
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false
      },
      view: {},
      ol_users: []
   }),
   mounted() {
      this.$soc.listen('ol', rsl => {
         this.ol_users = rsl.data;
      });
      this.$soc.listen('add-ol', rsl => {
         this.ol_users.push(rsl.data);
      });
      this.$soc.listen('del-ol', rsl => {
         const idx = this.ol_users.findIndex(x => x.soid === rsl.soid);
         if(idx > -1) {
            this.ol_users.splice(idx, 1);
         }
      });
      this.$soc.send({typ: 'indash'});
   },
   beforeDestroy() {
      this.$soc.unlisten('ol');
      this.$soc.unlisten('add-ol');
      this.$soc.unlisten('del-ol');
      this.$soc.send({typ: 'outdash'});
   },
   methods: {
      buzz() {
         this.$soc.send({typ:'broadcast',msg:'ping'});
      },
      hapus() {
         this.$store.dispatch('alert', {
            title: 'Submit your Github username',
            input: 'text',
            inputAttributes: {
               autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Look up',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
               return fetch(`//api.github.com/users/${login}`)
                  .then(response => {
                  if (!response.ok) {
                     throw new Error(response.statusText)
                  }
                  return response.json()
                  })
                  .catch(error => {
                  this.$swal.showValidationMessage(
                     `Request failed: ${error}`
                  )
                  })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
         }).then((result) => {
            if (result.value) {
               this.$store.dispatch('alert', {
                  title: `${result.value.login}'s avatar`,
                  imageUrl: result.value.avatar_url
               })
            }
         });
      }
   }
}
</script>
